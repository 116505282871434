@font-face {
  font-family: robotoMono;
  src: url("RobotoMono-Bold.a44c95f9.woff") format("woff");
  font-weight: 600;
}

:root {
  --primary: #a0faff;
  --dark-1: #404040;
  --light: #fbfbfb;
}

.svg-symbol {
  display: none;
}

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  --primary: #a0faff;
  --dark-1: #404040;
  --dark-2: #2d2d2d;
  --light: #fbfbfb;
  --background-color: var(--light);
  --text-color: var(--dark-1);
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  font-family: Roboto Mono, monospace;
  font-weight: 200;
  line-height: 1.6;
}

p, span, a {
  font-weight: 400;
}

p, span, a span {
  font-size: 1rem;
}

h1, h2 {
  color: var(--text-color);
  font-weight: 600;
  line-height: 1;
}

h2 {
  text-align: center;
  padding-top: 150px;
  padding-bottom: 150px;
  font-size: 3.5rem;
  display: block;
}

button {
  font-family: Roboto Mono, monospace;
  font-weight: 700;
}

a {
  color: var(--text-color);
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  font-weight: 500;
}

.h-line {
  height: 4px;
  width: 100%;
  background-color: #e4e4e4;
}

.page {
  width: 100%;
}

.container {
  background-color: var(--background-color);
  max-width: 1400px;
  width: calc(100% - 60px);
  margin: auto auto 60px;
  position: relative;
  top: 30px;
  box-shadow: 0 0 11px 4px #00000014;
}

.container:nth-child(2) {
  height: 735px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

@media screen and (max-width: 600px) {
  .page .container {
    width: 100vw;
    box-shadow: none;
    top: 0;
    overflow: hidden;
  }
}

@media screen and (max-width: 400px) {
  section.about h2, section.projects h2, section.contact h2 {
    padding-top: 90px;
    padding-bottom: 90px;
    font-size: 2.5rem;
  }
}

.custom-cursor {
  opacity: 0;
  width: 35px;
  height: 35px;
  z-index: 900;
  pointer-events: none;
  transition: opacity 1s;
  position: fixed;
}

.cursor {
  width: 35px;
  height: 35px;
  transition: opacity 1s;
}

.arrow-cursor, .dot-cursor, .dot-cursor circle:first-child {
  transform-origin: center;
}

body {
  cursor: none;
}

body:hover .cursor {
  --scale: 0: opacity: 1;
  transform: scale(var(--scale) );
}

body .cursor .arrow-cursor {
  opacity: 0;
}

/*# sourceMappingURL=index.f9a4ecef.css.map */
