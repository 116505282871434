@font-face {
  font-family: robotoMono;
  src: url(../assets/fonts/RobotoMono-Bold.woff) format('woff');
  font-weight: 600;
}

:root {
  --primary: rgb(160, 250, 255);
  --dark-1: rgb(64, 64, 64);
  --light: rgb(251, 251, 251);
}

.svg-symbol {
  display: none;
}

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  --primary: rgb(160, 250, 255);
  --dark-1: rgb(64, 64, 64);
  --dark-2: rgb(45, 45, 45);
  --light: rgb(251, 251, 251);
  --background-color: var(--light);
  --text-color: var(--dark-1);
  margin: 0;
  padding: 0;
  line-height: 1.6;
  font-family: 'Roboto Mono', monospace;
  font-weight: 200;

  background-color: var(--background-color);
  color: var(--text-color);
}

/* body.dark {
  --background-color: var(--dark-2);
  --text-color: var(--light);
} */

p,
span,
a {
  font-weight: 400;
}

p,
span,
a span {
  font-size: 1rem; /* .8 */
}

h1,
h2 {
  font-weight: 600;
  line-height: 1;
  color: var(--text-color);
}

h2 {
  font-size: 3.5rem;
  padding-top: 150px;
  padding-bottom: 150px;
  display: block;
  text-align: center;
}

button {
  font-family: 'Roboto Mono', monospace;
  font-weight: 700;
}

a {
  text-decoration: none;
  color: var(--text-color);
  cursor: pointer;
}

a:hover {
  font-weight: 500;
}

.h-line {
  height: 4px;
  background-color: rgb(228, 228, 228);
  width: 100%;
}

.page {
  width: 100%;
}

.container {
  background-color: var(--background-color);
  position: relative;
  margin: auto;
  max-width: 1400px;
  width: calc(100% - 60px);
  top: 30px;
  box-shadow: 0px 0px 11px 4px #00000014;
  margin-bottom: 60px;
}

.container:nth-child(2) {
  position: fixed;
  /* background-color: red; */
  height: 735px;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

@media screen and (max-width: 600px) {
  .page .container {
    width: 100vw;
    top: 0;
    overflow: hidden;
    box-shadow: none;
  }
}
@media screen and (max-width: 400px) {
  section.about h2,
  section.projects h2,
  section.contact h2 {
    font-size: 2.5rem;
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

/* cursor */
.custom-cursor {
  opacity: 0;
  width: 35px;
  height: 35px;
  position: fixed;
  z-index: 900;
  pointer-events: none;
  transition: opacity 1s;
}

.cursor {
  width: 35px;
  height: 35px;
  transition: opacity 1s;
}

.arrow-cursor,
.dot-cursor,
.dot-cursor circle:first-child {
  transform-origin: center;
}

body {
  cursor: none;
}

/* Starting custom cursor perameters */
body:hover .cursor {
  --scale: 0:
  opacity: 1;
  transform: scale(var(--scale))
}

body .cursor .arrow-cursor {
  opacity: 0;
}

/* Experement for drag (not working) */
/* .dot-cursor circle {
  transition-duration: 200ms;
  transition-timing-function: ease-out;
} */
